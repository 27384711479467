<template>
  <div>
    <v-card>
      <v-card-title class='px-2'>
        {{ $t('purchasingList') }}
        <v-spacer></v-spacer>
        <v-btn
          color='primary'
          class='d-none d-md-block'
          @click='isCreatePO = true'
        >
          <v-icon class='me-1'>
            {{ icons.mdiPlus }}
          </v-icon>
          <span class='d-none d-sm-block'>
            {{ $t('create_po') }}
          </span>
        </v-btn>
        <v-btn
          color='primary'
          class='d-block d-md-none'
          fab
          outlined
          icon
          @click='isCreatePO = true'
        >
          <v-icon>
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-row class='px-2 mb-2'>
        <v-col
          cols='12'
          md='6'
          lg='4'
        >
          <v-text-field
            v-model='searchText'
            :label="$t('search')"
            :placeholder="$t('wordingSearch')"
            @input='fetchDataTable'
            dense
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers='columns'
        :items='dataTableList'
        :options.sync='options'
        :server-items-length='totalDataTableList'
        :footer-props='footer'
        :loading='loading'
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template #item.purchase_id_pri='{ index }'>
          {{ segmentId + index + 1 }}
        </template>
        <template #item.purchase_id='{ item }'>
          <span class='primary--text text-decoration-underline font-weight-bold cursor-pointer'
                @click='dataDetailPo=item; isShowDetailPo = true'>
            {{ item.purchase_id }}
          </span>
        </template>
        <template #item.purchase_date='{ item }'>
          {{ item.purchase_date | sumdatetime }}
        </template>
        <template #item.purchase_price='{ item }'>
          {{ item.purchase_price | formatPrice }}
        </template>
        <template #item.purchase_date_import='{ item }'>
          <span v-if='item.purchase_status_id==4'>
            {{ item.purchase_date_import | sumdatetime }}
          </span>
          <span v-else>
-
          </span>
        </template>
        <template #item.purchase_status_id='{ item }'>
          <PoStatus :status='item.purchase_status_id' />
        </template>
        <template #item.users='{ item }'>
          <span v-if='item.purchase_status_id == 0'>
            {{ item.user_fullname_cancel }}
          </span>
          <span v-if='item.purchase_status_id == 1'>
            {{ item.user_fullname }}
          </span>
          <span v-if='item.purchase_status_id == 2'>
            {{ item.user_fullname_confirm }}
          </span>
          <span v-if='item.purchase_status_id == 3'>
            {{ item.user_fullname_pay }}
          </span>
          <span v-if='item.purchase_status_id == 4'>
            {{ item.user_fullname_import }}
          </span>
        </template>
        <template v-slot:[`item.actions`]='{ item }'>
          <v-menu :nudge-width='100' offset-y>
            <template v-slot:activator='{ on, attrs }'>
              <v-btn color='primary' class='px-2' dark small v-bind='attrs' v-on='on'>
                {{ $t('option')
                }}
                <v-icon small>
                  {{ icons.mdiMenuDownOutline }}
                </v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <v-list-item-group>
                <v-list-item :disabled='!item.purchaseorder' v-if='item.purchase_status_id== 1'
                             @click='dataManagePO=item; isManagePO=true'>
                  <v-list-item-subtitle>
                    <v-icon small class='me-1'> {{ icons.mdiCheckCircleOutline }}</v-icon>
                    {{ $t('manage') }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item :disabled='!item.purchaseorder' v-if='item.purchase_status_id== 2'
                             @click='showConfirmPay(item)'>
                  <v-list-item-subtitle>
                    <v-icon small class='me-1'> {{ icons.mdiCurrencyUsd }}</v-icon>
                    {{ $t('confirmPay') }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item :disabled='!item.purchaseorder'
                             v-if='item.purchase_status_id== 3'
                             @click='importComment = item.purchase_comment ; idConfirmImport=item.purchase_id_pri;isShowConfirmImport = true'>
                  <v-list-item-subtitle>
                    <v-icon small class='me-1'> {{ icons.mdiCubeOutline }}</v-icon>
                    {{ $t('warehouseImport') }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item @click='print(item.purchase_id_pri)'>
                  <v-list-item-subtitle>
                    <v-icon small class='me-1'> {{ icons.mdiPrinter }}</v-icon>
                    {{ $t('printPO') }}
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item
                  @click='idCancelPo=item.purchase_id_pri; isShowAlertConfirmCancel = true '>
                  <v-list-item-subtitle>
                    <v-icon small class='me-1'> {{ icons.mdiDeleteOutline }}</v-icon>
                    {{ $t('cancel') }}
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-divider></v-divider>
      <Pagination
        :page-data='options.page'
        :page-count='totalPage'
        :segment-id='+segmentId '
        :count-list='dataTableList.length'
        :total='+totalDataTableList'
        @pageChanged='(page)=>{options.page = page ; fetchDataTable()}'
        @itemChanged='(items)=>{options.itemsPerPage = items;options.page=1 ; fetchDataTable()}'
      />
    </v-card>
    <CreatePo v-model='isCreatePO' @onAdd='fetchDataTable' />
    <ManagePO v-model='isManagePO' :po-data='dataManagePO' @onUpdate='fetchDataTable' />
    <UserConfirm v-model='isShowConfirmImport' @onSend='confirmImport' :comment-status='true'
                 :comment='importComment' />
    <v-dialog v-model='isShowConfirmPay' persistent max-width='500'>
      <v-card>
        <v-card-title>
          {{ $t('confirmPayPo') }}
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model='payComment'
            :label="$t('note')"
            :placeholder="$t('note')"
            outlined
            dense
            rows='3'
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='primary' @click='confirmPay'>
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color='secondary' outlined @click='isShowConfirmPay = false'>
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <UserConfirm v-model='isShowCancelPo' @onSend='onCancelPo' :comment-status='true'
                 :comment='``' />
    <DetailPo v-model='isShowDetailPo' :po-data='dataDetailPo' />
    <v-dialog v-model='isShowAlertConfirmCancel' persistent max-width='500'>
      <v-card>
        <v-card-title class='text-center'>
          <span>{{ $t('alertCancelPO') }}</span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='error' @click='isShowCancelPo = true;isShowAlertConfirmCancel=false'>
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color='secondary' @click='isShowAlertConfirmCancel = false' outlined>
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import usePo from '@/views/po/usePo'
import {
  mdiCheckCircleOutline,
  mdiPlus,
  mdiPrinter,
  mdiDeleteOutline,
  mdiMenuDownOutline,
  mdiCurrencyUsd,
  mdiCubeOutline,
  mdiAlertOutline,
} from '@mdi/js'
import Pagination from '@/components/basicComponents/Pagination.vue'
import PoStatus from '@/components/basicComponents/PoStatus.vue'
import { sumdatetime, formatPrice } from '@/plugins/filters'
import CreatePo from '@/views/po/CreatePo'
import ManagePO from '@/views/po/ManagePO'
import UserConfirm from '@/components/basicComponents/UserConfirm'
import DetailPo from '@/views/po/DetailPo'

export default {
  components: {
    DetailPo,
    UserConfirm,
    ManagePO,
    CreatePo,
    Pagination,
    PoStatus,

  },
  filters: { sumdatetime, formatPrice },
  setup() {
    return {
      icons: {
        mdiPlus,
        mdiPrinter,
        mdiDeleteOutline,
        mdiCheckCircleOutline,
        mdiMenuDownOutline,
        mdiCubeOutline,
        mdiCurrencyUsd,
        mdiAlertOutline,
      },
      ...usePo(),
    }
  },

}
</script>

<style scoped lang='scss'>

</style>
