<template>
  <div>
    <v-dialog v-model='isCreatePo' persistent max-width='1000'>
      <v-card>
        <v-card-title>
          <span>
            {{ $t('create_po') }} <v-chip small color='secondary'>{{ poId }}</v-chip>
          </span>
          <v-spacer></v-spacer>
          <v-autocomplete
            v-model='dealerSelected'
            outlined
            dense
            :label="$t('selectDealer')"
            :items='drugfromList'
            hide-details='auto'
            style='max-width: 350px'
            return-object
            :no-data-text="$t('no_information')"
            item-value='drugfrom_id'
            item-text='drugfrom_name'
          >
          </v-autocomplete>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class='pt-1 pb-0'>
          <h4 class='font-weight-bold text-decoration-underline'>
            {{ $t('dealerInformation') }}
          </h4>
          <v-row>
            <v-col cols='12' md='6' lg='4'>
              <span class='font-weight-bold'>{{ $t('dealer') }} : </span>
              <span>{{ dealerSelected.drugfrom_name }}</span>
            </v-col>
            <v-col cols='12' md='6' lg='4'>
              <span class='font-weight-bold'>{{ $t('tax_number') }} : </span>
              <span>{{ dealerSelected.drugfrom_tax }}</span>
            </v-col>
            <v-col cols='12' md='6' lg='4'>
              <span class='font-weight-bold'>{{ $t('tel') }} : </span>
              <span>{{ dealerSelected.drugfrom_tel }}</span>
            </v-col>
          </v-row>
          <v-divider class='my-1'></v-divider>
          <v-card flat color='transparent' :disabled='!dealerSelected.drugfrom_id'>
            <div class='d-flex align-center justify-space-between my-2'>
              <h4 class='font-weight-bold text-decoration-underline'>
                {{ $t('list_drug_equipment') }}
              </h4>
              <v-btn color='primary' @click='isAddDrugToCart = true'>
                <v-icon class='me-1'>
                  {{ icons.mdiPlus }}
                </v-icon>
                <span class='d-none d-sm-block'>
                        {{ $t('addDrugAndEquipment') }}
                      </span>
              </v-btn>
            </div>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                <tr>
                  <th>{{ $t('code') }}</th>
                  <th style='min-width: 200px'>{{ $t('list') }}</th>
                  <th>{{ $t('tax') }}</th>
                  <th>{{ $t('amount') }}</th>
                  <th>{{ $t('cost_price') }}</th>
                  <th>{{ $t('total_price') }}</th>
                  <th>{{ $t('unit') }}</th>
                  <th class='text-center'>{{ $t('option') }}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for='(item,index) in cartList' :key='index'>
                  <td>{{ item.purchaseorder_drug_id }}</td>
                  <td style='min-width: 200px'>{{ item.purchaseorder_drug_name }}</td>
                  <td>{{ item.purchaseorder_vat }}%</td>
                  <td>{{ item.purchaseorder_amount }}</td>
                  <td>{{ item.purchaseorder_cost }}</td>
                  <td>{{ item.purchaseorder_total }}</td>
                  <td>{{ item.purchaseorder_drug_unit }}</td>
                  <td class='text-center'>
                    <v-btn icon fab small color='error' @click='removeCart(item)'>
                      <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                    </v-btn>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>

          </v-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color='primary' :disabled='!dealerSelected.drugfrom_id || !cartList.length'
                 @click='isConfirmCreatePo = true'>
            {{ $t('confirm') }}
          </v-btn>
          <v-btn color='secondary' outlined @click="$emit('update:isCreatePo',false)">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
        <v-dialog v-model='isAddDrugToCart' persistent max-width='500'>
          <v-card>
            <v-card-title>
              {{ $t('addDrugAndEquipment') }}
              <v-spacer></v-spacer>
              <div>
                <span>{{ $t('total_price') }} :</span>
                <span class='error--text'>
                  {{ (purchaseorder_amount * purchaseorder_cost) + (purchaseorder_amount * purchaseorder_cost * purchaseorder_vat / 100) | formatPrice
                  }}
                </span>
              </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-form
              ref='formAddDrugToCart'
              @submit.prevent='addDrugToCart'
            >
              <v-card-text>
                <v-autocomplete
                  v-model='drug_id_pri'
                  outlined
                  dense
                  :label="$t('list_drug_equipment')"
                  :items='drugList'
                  hide-details='auto'
                  class='mt-5'
                  :no-data-text="$t('no_information')"
                  item-text='drug_name'
                  item-value='drug_id_pri'
                >
                  <template v-slot:item='{ item }'>
                <span v-if='item.drug_id' class='me-1'>
                  ({{ item.drug_id }})
                </span>
                    <span>
                  {{ item.drug_name }}
                </span>
                  </template>
                  <template v-slot:selection='{ item }'>
                <span v-if='item.drug_id' class='me-1'>
                  ({{ item.drug_id }})
                </span>
                    <span>
                  {{ item.drug_name }}
                </span>
                  </template>
                </v-autocomplete>
                <v-text-field
                  v-model.trim='purchaseorder_cost'
                  hide-details='auto'
                  class='mt-5'
                  type='number'
                  :label="$t('cost_price')"
                  outlined
                  :rules='[required]'
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-model.trim='purchaseorder_amount'
                  hide-details='auto'
                  class='mt-5'
                  type='number'
                  :label="$t('amount')"
                  outlined
                  :rules='[required]'
                  dense
                >
                </v-text-field>
                <v-text-field
                  v-model.trim='purchaseorder_vat'
                  hide-details='auto'
                  class='mt-5'
                  type='number'
                  :label="`${$t('tax')} %`"
                  outlined
                  :rules='[required]'
                  dense
                >
                </v-text-field>

              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color='primary' type='submit' :loading='addCartLoading'
                       :disabled='addCartLoading'>{{ $t('confirm') }}
                </v-btn>
                <v-btn color='secondary' outlined @click='isAddDrugToCart = false'>
                  {{ $t('cancel') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>

        </v-dialog>
        <v-dialog v-model='isConfirmCreatePo' persistent max-width='500'>
          <v-card>
            <v-card-title>
              {{ $t('confirmList') }}
            </v-card-title>
            <v-form ref='formConfirmCreatePo' @submit.prevent='createPo'>
              <v-card-text>
                <v-dialog
                  ref='dateStart'
                  v-model.trim='isShowDate'
                  :return-value.sync='purchase_expdate'
                  width='290px'
                >
                  <template v-slot:activator='{ on, attrs }'>
                    <v-text-field
                      v-model='purchase_expdate'
                      outlined
                      dense
                      :rules='[required]'
                      :label="$t('dateExpireStart')"
                      v-bind='attrs'
                      v-on='on'
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model.trim='purchase_expdate'
                    :locale='$i18n.locale'
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color='primary'
                      @click='$refs.dateStart.save(purchase_expdate)'
                    >
                      {{ $t('confirm') }}
                    </v-btn>
                    <v-btn
                      text
                      color='primary'
                      @click='isShowDate = false'
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-date-picker>
                </v-dialog>
                <v-textarea v-model='purchase_comment' rows='3'
                            :label="$t('note')"
                            outlined
                            dense></v-textarea>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color='primary' type='submit'
                       :loading='createPoLoading'
                       :disabled='createPoLoading'>
                  {{ $t('confirm') }}
                </v-btn>
                <v-btn color='secondary' outlined @click='isConfirmCreatePo = false'>
                  {{ $t('cancel') }}
                </v-btn>
              </v-card-actions>
            </v-form>

          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { ref, watch, onMounted } from '@vue/composition-api'
import dealer from '@/api/DrugManagement/dealer'
import po from '@/api/DrugManagement/po'
import { mdiPlus, mdiDeleteOutline } from '@mdi/js'
import { required } from '@core/utils/validation'
import store from '@/store'
import { i18n } from '@/plugins/i18n'
import { formatPrice } from '@/plugins/filters'
import doc from '@/api/systemSetting/document'
import document from '@/api/systemSetting/document'

export default {
  model: {
    prop: 'isCreatePo',
    event: 'update:isCreatePo',
  },
  props: {
    isCreatePo: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    formatPrice,
  },
  setup(props, { emit }) {
    const drug_id_pri = ref('')
    const purchaseorder_vat = ref('')
    const purchaseorder_amount = ref('')
    const purchaseorder_cost = ref('')
    const drugfromList = ref([])
    const drugList = ref([])
    const dealerSelected = ref({})
    const isAddDrugToCart = ref(false)
    const formAddDrugToCart = ref(null)
    const cartList = ref([])
    const poId = ref('')
    const isConfirmCreatePo = ref(false)
    const formConfirmCreatePo = ref(null)
    const purchase_expdate = ref('')
    const purchase_comment = ref('')
    const isShowDate = ref(false)
    const addCartLoading = ref(false)
    const createPoLoading = ref(false)
    const { documentSetting } = document


    const removeCart = async (item) => {
      const res = await po.poRemoveCart(item.purchaseorder_temp_id_pri)
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      getCartList()
    }

    const getDrugfromList = async () => {

      const data = await dealer.dealerList()
      drugfromList.value = data
    }

    const getDrugList = async () => {
      const body = {
        searchtext: '',
        drug_status_id: 1,
        drug_category_id: '',
        druggroup_id: '',
        drugtype_id: '',
        active_page: 1,
        per_page: '0',
      }
      const { data } = await po.poDrugList(body)
      drugList.value = data
    }

    const getDocument = async () => {
      const data = await documentSetting()
      poId.value = data.purchase
    }

    const addDrugToCart = async () => {
      const isFormValid = formAddDrugToCart.value.validate()
      if (!isFormValid) return
      addCartLoading.value = true
      const res = await po.poAddDrugToCart({
        drug_id_pri: drug_id_pri.value,
        purchaseorder_vat: purchaseorder_vat.value,
        purchaseorder_amount: purchaseorder_amount.value,
        purchaseorder_cost: purchaseorder_cost.value,
        purchaseorder_total: (purchaseorder_amount.value * purchaseorder_cost.value) + (purchaseorder_amount.value * purchaseorder_cost.value * purchaseorder_vat.value / 100),
      })
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      addCartLoading.value = false
      if (res.response) {
        clearFormAddToCart()
        isAddDrugToCart.value = false
        getCartList()
      }
    }

    const clearFormAddToCart = () => {
      drug_id_pri.value = ''
      purchaseorder_vat.value = '0'
      purchaseorder_amount.value = ''
      purchaseorder_cost.value = ''
      purchase_expdate.value = ''
      purchase_comment.value = ''
    }

    const getCartList = async () => {
      const { data } = await po.poCartList()
      cartList.value = data
    }


    const createPo = async () => {
      const isFormValid = formConfirmCreatePo.value.validate()
      if (!isFormValid) return
      createPoLoading.value = true

      const res = await po.poCreate({
        drugfrom_id: dealerSelected.value.drugfrom_id,
        purchase_expdate: purchase_expdate.value,
        purchase_comment: purchase_comment.value,
        lang: i18n.locale,
      })
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      createPoLoading.value = false
      emit('update:isCreatePo', false)
      emit('onAdd', false)
    }


    watch(() => props.isCreatePo, (val) => {
      if (val) {
        dealerSelected.value = {}
        clearFormAddToCart()
        getCartList()
        getDrugList()
        getDrugfromList()
        getDocument()
      }
    })

    return {
      icons: {
        mdiPlus,
        mdiDeleteOutline,
      },
      drug_id_pri,
      cartList,
      createPo,
      isAddDrugToCart,
      drugfromList,
      formAddDrugToCart,
      removeCart,
      getCartList,
      addDrugToCart,
      poId,
      purchase_comment,
      required,
      isConfirmCreatePo,
      drugList,
      dealerSelected,
      purchaseorder_vat,
      purchaseorder_amount,
      purchaseorder_cost,
      purchase_expdate,
      isShowDate,
      formConfirmCreatePo,
      addCartLoading,
      createPoLoading,
    }
  },

}
</script>
